<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader
  },
  page: {
    title: "Saques",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      title: "Detalhe saque",
      items: [
        {
          text: "Saques",
          href: "/cashout"
        },
        {
          text: "Detalhe",
          active: true
        }
      ],
      cashout: null,
      submitted: false,
      showmodal: false,
      vExecutedRadio: null,
      newReceipt: {
        "executed": null,
        "receipt": null
      },
      user: null,
      file: '',
    };
  },
  validations: {
    newReceipt: {
      executed: { required },
      receipt: { required }
    }
  },
  created(){
    this.user = JSON.parse(localStorage.getItem('user'));
    this.loading = true
    let config = {
      method: 'get',
      url: `${process.env.VUE_APP_API}api/v1/admin/cashout/${this.$route.params.id}`,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': `Bearer ${this.user.access_token}`
      }
    };

    axios(config)
    .then( (response) => {
      this.loading = false
      this.cashout = response.data.data
    })
    .catch((error) => {
      console.log(error)
      this.$router.push('/pages/error-404')
    });
  },
  methods: {
    handleSubmit() {
      this.submitted = true;

      if(this.vExecutedRadio === false) {
        this.newReceipt.receipt = null;
      } else {
        this.newReceipt.executed = this.vExecutedRadio;
      }

      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;

        console.log(this.newReceipt);
        let config = {
          method: 'put',
          url: `${process.env.VUE_APP_API}api/v1/admin/cashout/${this.$route.params.id}`,
          headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${this.user.access_token}`
          },
          data: this.newReceipt,
        };
        axios(config)
        // eslint-disable-next-line no-unused-vars
        .then( response => {
          this.loading = false
          this.submitted = false
          this.newReceipt = {
              "executed": null,
              "receipt": null,
          }
          this.$bvToast.toast('Comprovante adicionado com sucesso', {
            title: 'Tudo certo!',
            variant: 'success',
            solid: true
          });
          this.showmodal = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch( (error) => {
          console.log("error: ", error)
          this.loading = false
          this.$bvToast.toast('Ocorreu um erro ao enviar o comprovante', {
            title: 'Ocorreu um erro',
            variant: 'danger',
            solid: true
          });
        });
      }
    },

    hideModal() {
      this.submitted = false;
      this.showmodal = false;
    },

    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = () => {
        console.log("onload: ", reader.result);
        this.newReceipt.receipt = reader.result;
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    },

    handleReceiptUpload() {
      const fileUploaded = this.$refs.file.files[0];
      this.createBase64File(fileUploaded);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div v-if="cashout">

      <div class="row" v-if="cashout.cashout.pending">
        <div class="col-lg-12 mb-2">
          <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
            <i class="mdi mdi-plus mr-2"></i> Adicionar comprovante
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-xl-12">
                  <div class="mt-4 mt-xl-3">
                    <h3 class="mt-1 mb-3">Informações do Usuário</h3>

                    <h5 class="font-size-14 mt-2">Nome :</h5>
                    <div class="text-muted">
                      {{cashout.user.name}}
                    </div>

                    <h5 class="font-size-14 mt-2">E-mail :</h5>
                    <div class="text-muted">
                      {{cashout.user.email}}
                    </div>
                    
                    <h5 class="font-size-14 mt-2">Identidade :</h5>
                    <div class="text-muted">
                      {{cashout.user.identity}}
                    </div>
                    
                    <h5 class="font-size-14 mt-2">Telefone :</h5>
                    <div class="text-muted">
                      {{cashout.user.phone}}
                    </div>
                    
                    <h5 class="font-size-14 mt-2">Data de Nascimento :</h5>
                    <div class="text-muted">
                      {{cashout.user.birthday | date}}
                    </div>

                    <hr class="my-4" />
                    
                  </div>
                </div>

              </div>
              <!-- end row -->

            </div>
          </div>
          <!-- end card -->
        </div>
        
        <div class="col-lg-4">

          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-xl-12">
                  <div class="mt-4 mt-xl-3">
                    <h3 class="mt-1 mb-3">Informações do Saque</h3>

                    <h5 class="font-size-14 mt-2">Valor :</h5>
                    <div class="text-muted">
                      R$ {{cashout.cashout.value | price}}
                    </div>

                    <h5 class="font-size-14 mt-2">Criado em :</h5>
                    <div class="text-muted">
                      {{cashout.cashout.created_at | date}}
                    </div>
                    
                    <h5 class="font-size-14 mt-2">Última atualização :</h5>
                    <div class="text-muted">
                      {{cashout.cashout.update_at | date}}
                    </div>
                    
                    <h5 class="font-size-14 mt-2">Status :</h5>
                    <div class="text-muted">
                      {{cashout.cashout.pending ? "Pendente" : cashout.cashout.recused ? "Recusado" : "OK"}}
                    </div>

                    <hr class="my-4" />
                    
                  </div>
                </div>

              </div>
              <!-- end row -->

            </div>
          </div>
          <!-- end card -->
        </div>
        
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-xl-12">
                  <div class="mt-4 mt-xl-3">
                    <h3 class="mt-1 mb-3">Dados Bancários</h3>
                    
                    <div v-if="cashout.bank_informations.pix_key">
                      <h5 class="font-size-14 mt-2">Tipo de Chave PIX :</h5>
                      <div class="text-muted">
                        {{cashout.bank_informations.pix_type ? cashout.bank_informations.pix_type : "-" }}
                      </div>

                      <h5 class="font-size-14 mt-2">Chave PIX :</h5>
                      <div class="text-muted">
                        {{cashout.bank_informations.pix_key ? cashout.bank_informations.pix_key : "-" }}
                      </div>
                    </div>
                    <div v-else>
                      <h5 class="font-size-14 mt-2">Banco :</h5>
                      <div class="text-muted">
                        {{cashout.bank_informations.bank_code}} - {{cashout.bank_informations.bank}}
                      </div>

                      <h5 class="font-size-14 mt-2">Agência :</h5>
                      <div class="text-muted">
                        {{cashout.bank_informations.agency}}
                      </div>

                      <h5 class="font-size-14 mt-2">Conta :</h5>
                      <div class="text-muted">
                        {{cashout.bank_informations.account}}
                      </div>
                      
                      <h5 class="font-size-14 mt-2">Tipo de conta :</h5>
                      <div class="text-muted">
                        {{cashout.bank_informations.type_account === 'cp' ? "Conta Poupança" : "Conta Corrente"}}
                      </div>
                    </div>

                    <hr class="my-4" />
                    
                  </div>
                </div>

              </div>
              <!-- end row -->

            </div>
          </div>
          <!-- end card -->
        </div>

        <div class="col-lg-12" v-if="!cashout.cashout.pending">
          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-xl-12">
                  <div class="mt-4 mt-xl-3">
                    <h3 class="mt-1 mb-3">Comprovante</h3>
                    
                    <div v-if="cashout.cashout.receipt">
                      <div class="text-muted">
                        <a
                          :href="cashout.cashout.receipt.url" target="_blank">
                          Download
                        </a>
                      </div>
                    </div>

                    <hr class="my-4" />
                    
                  </div>
                </div>

              </div>
              <!-- end row -->

            </div>
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end row -->


    </div>
    <!-- end row -->

    <b-row v-else>
      <b-col>
        <b-skeleton-img></b-skeleton-img>
      </b-col>
      <b-col>
        <b-skeleton></b-skeleton>
        <div class="m-2"></div>
        <b-skeleton height="60px"></b-skeleton>
        <div class="m-4"></div>
        <b-skeleton></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
      </b-col>
    </b-row>

    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Adicionar comprovante"
      title-class="text-dark font-18"
      hide-footer
    >
      <b-overlay :show="loading" rounded="sm">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <h5>Transação realizada com sucesso?</h5>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="executedRadio" id="executedTrue" value="true" v-model="vExecutedRadio">
              <label class="form-check-label" for="executedTrue">Sim</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="executedRadio" id="executedFalse" value="false" v-model="vExecutedRadio">
              <label class="form-check-label" for="executedFalse">Não</label>
            </div>
          </div>

          <div class="form-group" v-if="vExecutedRadio === 'true'">
            <label for="inputReceipt">Enviar comprovante
              <input type="file" class="form-control-file" id="inputReceipt" ref="file" v-on:change="handleReceiptUpload()"/>
            </label>
          </div>
          
          <div class="form-group" v-else-if="vExecutedRadio === 'false'">
            <label for="textareaReason">Qual o motivo?</label>
            <textarea class="form-control" id="textareaReason" rows="3"></textarea>
          </div>
          <div class="row" v-else></div>

          <div class="text-right">
            <button type="submit" class="btn btn-success">Enviar</button>
            <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
          </div>
        </form>
      </b-overlay>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>